import React from 'react'
import '../Screen.css';
import ConsultationForm from './ConsultationForm';

function HomeBottom() {
    return (
        <div>
            <h1 style={{ marginLeft: '10%', marginRight: '10%' }}>
                Ready to <text className='specialText'>revolutionize</text> your brand?
            </h1>
            <div id='consultationBox' className='firstBox'>
                {/* <text className='specialText'>Chainlab</text> offers the best in-house services for NFT brand creation
                and expansion. <br />We leverage the blockchain to bring you the fastest, most efficient,
                and highest quality product to <text className='specialText'>ensure</text> brand and community growth. */}
                <h3>Book a free consultation with a <text className='specialText'>Chainlab</text> expert now.</h3>
                <ConsultationForm/>
            </div>
        </div>
    )
}

export default HomeBottom