import React from 'react'
import '../Screen.css'

//import components
import HomeTop from './HomeTop';
import HomeBottom from './HomeBottom';


// importing icons
import { FaEthereum, FaPaintBrush } from 'react-icons/fa';
import { AiFillLock } from 'react-icons/ai';
import { MdAttachMoney } from 'react-icons/md';
import HomeCarousel from './HomeCarousel';

function Home() {
    return (
        <div className='homeContainer'>
            <HomeTop />
            <h1 style={{marginTop: '.5rem'}}>What we do:</h1>
            <div className='iconBoxContainer1'>
                <div className='iconBox'>
                    Build robust <text className='specialText'>smart contracts</text> on the blockchain.
                    <br />
                    <div className='icons'>
                        <FaEthereum />
                    </div>
                </div>
                <div className='iconBox'>
                    Code fully customized <text className='specialText'>minting</text> / <text className='specialText'>landing</text> pages.
                    <br />
                    <div className='icons'>
                        <FaPaintBrush />
                    </div>
                </div>
            </div>
            <div className='iconBoxContainer2'>
                <div className='iconBox'>
                    Customize NFT <text className='specialText'>tokenomics</text> to best suit your brand.
                    <br />
                    <div className='icons'>
                        <MdAttachMoney />
                    </div>
                </div>
                <div className='iconBox'>
                    Create <text className='specialText'>highly secure databases</text> to store your project.
                    <br />
                    <div className='icons'>
                        <AiFillLock />
                    </div>
                </div>
            </div>
            {/* <h1>Client Reviews</h1> */}
            {/* <HomeCarousel/> */}
            <HomeBottom />
        </div>
    )
}

export default Home