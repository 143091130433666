import React from 'react'
// import chainlabPicture from './Chainlab.png'
import './Navbar.css'

function Navbar(props) {

    function setScreenToHome() {
        props.setCurrentScreen('home');
    }
    function setScreenToPricing() {
        props.setCurrentScreen('pricing');
    }
    function setScreenToPortfolio() {
        props.setCurrentScreen('portfolio');
    }
    function setScreenToContact() {
        props.setCurrentScreen('home');
    }
    // function setScreenToAbout() {
    //     props.setCurrentScreen('about');
    // }


    return (
        <div className='navbar'>
            <img onClick={setScreenToHome} className='navbarImage' src={"https://chainlab.s3.amazonaws.com/Chainlab.png"} />
            <text className='navbarItems'>
            <button onClick={setScreenToContact}>home</button> 
                <button onClick={setScreenToPricing}>pricing</button> 
                <button onClick={setScreenToPortfolio}>portfolio</button> 
                {/* <button onClick={setScreenToAbout}>about</button> */}
            </text>
        </div>
    )
}

export default Navbar