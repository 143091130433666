import React from "react";
import "../Screen.css";
import Cheeky from "../Assets/Cheeky.png";
import Bandit from "../Assets/Bandit.png";
import Dolphin from "../Assets/Dolphin.png";
import Plants from "../Assets/Plants.png";
import Krebels from "../Assets/Krebels.png";
import BugPunks from "../Assets/BugPunks.PNG";
import Boo from "../Assets/Boo.png";
import Doodlenauts from "../Assets/Doodlenauts.png";
import NikNames from "../Assets/NikNames.png";
import PortTop from "./PortTop"

function Portfolio() {
  return (
    <div className="portContainer">
      <PortTop />
      <div className="projectsDiv">
        <h4>
          <a
            href="https://cheekychimps.club/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Cheeky}></img>
          </a>
        </h4>
        <h4>
          <a
            href="https://deadbanditz.github.io/banditzsite/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Bandit}></img>
          </a>
        </h4>
        <h4>
          <a
            href="https://diamonddolphins.club/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Dolphin}></img>
          </a>
        </h4>
        <h4>
          <a
            href="https://powerplantsnft.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Plants}></img>
          </a>
        </h4>
        <h4>
          <a
            href="https://krebels.io/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Krebels}></img>
          </a>
        </h4>
        <h4>
          <a
            href="https://cryptobugpunks.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={BugPunks}></img>
          </a>
        </h4>
        <h4>
          <a
            href="https://seanpen00.github.io/boocrewnftminter/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Boo}></img>
          </a>
        </h4>
        <h4>
          <a
            href="https://doodlenauts.io"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Doodlenauts}></img>
          </a>
        </h4>
        <h4>
          <a
            href="https://niknamesnft.github.io/minting/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              style={{ backgroundColor: "black", padding: 10 }}
              src={NikNames}
            ></img>
          </a>
        </h4>
      </div>
    </div>
  );
}

export default Portfolio;
