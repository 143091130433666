import React from "react";
import "../Screen.css";

//import components
import PricingTop from "./PricingTop";
import HomeBottom from "../Home/HomeBottom";

// importing icons
import { FaEthereum, FaPaintBrush } from "react-icons/fa";
import { AiFillLock } from "react-icons/ai";
import { MdAttachMoney } from "react-icons/md";
import HomeCarousel from "../Home/HomeCarousel";

function Pricing() {
  return (
    <div className="homeContainer">
      <PricingTop />
      {/* <h1 style={{marginTop: '.5rem'}}>What we do:</h1> */}
      <div className="PricingiconBoxContainer1">
        <div className="pricingBox">
          <div className="PricingiconBox">
            <text
              className="specialText"
              style={{ fontSize: "3vh", fontWeight: "bold" }}
            >
              Budget
            </text>
            <br />
            <text className="specialText">Our most affordable package.</text>
            <br />
            <br />
            • Basic ERC-721 Smart Contract
            <br />
            <br />
            • Secure Mutable Database
            <br />
            <br />
            • Basic Minting Page
            <br />
            <br />
            • Subscription Based Web Hosting
            <br />
          </div>
          <div className="pricesBottom">
            <div className="Pricingicons">$500</div>+ 25% Smart Contract Equity
          </div>
        </div>
        <div className="pricingBox">
          <div className="PricingiconBox">
            <text
              className="specialText"
              style={{ fontSize: "3vh", fontWeight: "bold" }}
            >
              Standard
            </text>
            <br />
            <text className="specialText">Our most popular package.</text>
            <br />
            <br />
            • Basic ERC-721 or ERC-1155 Smart Contract
            <br />
            <br />
            • Secure Immutable Database
            <br />
            <br />
            • Basic Minting & Landing Pages
            <br />
            <br />
            • Subscription Based Web Hosting
            <br />
          </div>
          <div className="pricesBottom">
            <div className="Pricingicons">$1500</div>+ 15% Smart Contract Equity
          </div>
        </div>
        <div className="pricingBox">
          <div className="PricingiconBox">
            <text
              className="specialText"
              style={{ fontSize: "3vh", fontWeight: "bold" }}
            >
              Premium
            </text>
            <br />
            <text className="specialText">
              Our most advanced, enterprise level package.
            </text>
            <br />
            <br />• <text>Fully Customized</text>, <text>Advanced</text> ERC-721
            or ERC-1155 Smart Contract
            <br />
            <br />
            • Highly Secure Immutable Database
            <br />
            <br />
            • Fully customized Minting & Landing Pages
            <br />
            <br />
            • Unlimited High Speed Web Hosting
            <br />
          </div>
          <div className="pricesBottom">
            <div className="Pricingicons">$3000</div>+ 10% Smart Contract Equity
          </div>
        </div>
      </div>
      {/* <h1>Client Reviews</h1> */}
      {/* <HomeCarousel/> */}
      <HomeBottom />
    </div>
  );
}

export default Pricing;
