import React from 'react'
import '../Screen.css';
import { Link, animateScroll as scroll } from "react-scroll";


function PricingTop() {
    return (
        <div>
            <h1 className='headingText'>
                Choose a <text className='specialText'>price</text> that's right for you.
            </h1>
            {/* <Link
            activeClass='active'
            to='consultationBox'
            smooth={true}
            >
                <button className='consultationButton'>Start a Project</button>
            </Link> */}

        </div>
    )
}

export default PricingTop