import React from 'react'
import '../Screen.css';
import { Link, animateScroll as scroll } from "react-scroll";


function PortTop() {
    return (
        <div>
            <h1 className='headingText'>
                Some of our <text className='specialText'>projects.</text>
            </h1>
            <p>While we've worked on dozens of projects, these are projects that are <text className='specialText'>officially partnered</text> with us.</p>
            {/* <Link
            activeClass='active'
            to='consultationBox'
            smooth={true}
            >
                <button className='consultationButton'>Start a Project</button>
            </Link> */}

        </div>
    )
}

export default PortTop