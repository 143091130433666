import React from 'react'
import { useState } from 'react'
import Home from './Home/Home'
import Pricing from './Pricing/Pricing'
import Portfolio from './Portfolio/Portfolio'
import About from './About'
import './MainScreen.css'

function MainScreen(props) {

    return (
        <div>
            {props.currentScreen === 'pricing' ? <Pricing />
            : props.currentScreen === 'portfolio' ? <Portfolio/>
            : props.currentScreen === 'home' ? <Home/>
            : props.currentScreen === 'about' ? <About/>
            : <Home/>
            }
            
        </div>
    )
}

export default MainScreen