import React from 'react'

function About() {
  return (
    <div className='homeContainer'>

    <text>
      Coming Soon.
    </text>

    </div>
  )
}

export default About