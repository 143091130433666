import React from 'react'
import { useState } from 'react';
import emailjs from 'emailjs-com';
import {AiOutlineSend} from 'react-icons/ai'
import './ConsultationForm.css'

function ConsultationForm() {

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [desc, setDesc] = useState('');
  const [submitSuccess, setSubmitSuccess] = useState('Submit');

  function submitForm(event) {
    event.preventDefault();

    const finalData = {
      user_email: email,
      user_name: name,
      message: desc
    }

    emailjs.send('service_9n6rchm', 'template_3id87u5', finalData, 'b8ZuIKA2-xOX6uGhb')
      .then((result) => {
        console.log('SUCCESS!', result.status, result.text);
        setSubmitSuccess('Success');
         //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
      }, (error) => {
        console.log(error.text);
      });

    setEmail('');
    setName('');
    setDesc('');

    setTimeout(()=> {
      setSubmitSuccess('Submit');
    }, 10000);

    console.log(finalData);
  }

  function updateEmailText(event) {
    setEmail(event.target.value);
  }

  function updateNameText(event) {
    setName(event.target.value);
  }

  function updateDescText(event) {
    setDesc(event.target.value);
  }

  const sendIcon = <AiOutlineSend/>

  return (
    <div className='container'>
      <form className='formContainer'>
        <text className='consultationText'>Email: </text>
        <br />
        <input value={email} onChange={updateEmailText} className='input' placeholder='you@email.com' type={'text'}></input>
        <br />
        <br />
        <text className='consultationText'>Name: </text>
        <br />
        <input value={name} onChange={updateNameText} className='input' placeholder='Alan Turing' type={'text'}></input>
        <br />
        <br />
        <text className='consultationText'>Description: </text>
        <br />
        <textarea value={desc} onChange={updateDescText} className='input description' placeholder='Describe your project idea.' type={'text'}></textarea>
        <br />
        <button type='submit' onClick={submitForm} className='consultationButton sendIcon'>
        {submitSuccess}
        </button>
      </form>
    </div>
  )
}

export default ConsultationForm