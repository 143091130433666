import React from 'react'
import '../MainScreen/Screen.css';
import 'react-icons/fa';
import { GiHearts } from 'react-icons/gi';

function Footer() {
    return (
        <div className='footerContainer'>
            <text style={{marginLeft: 10}}>
            ©2023 Chainlab, LLC
            </text>
            <text style={{marginRight: 10}}>
                Made with <GiHearts /> in Virginia
            </text>
        </div>
    )
}

export default Footer