import './App.css';
import { useState } from 'react';
import MainScreen from './components/MainScreen/MainScreen';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';

function App() {

  const [currentScreen, setCurrentScreen] = useState('');

  function getCurrentScreen(screen) {
    setCurrentScreen(screen);
    console.log(currentScreen);
  }

  return (
    <div className='App'>
      <Navbar setCurrentScreen={getCurrentScreen} />
      <MainScreen currentScreen={currentScreen}/>
      <Footer/>
    </div>
  );
}

export default App;
